


















































































































import {Component, Prop} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import {
	VacationBalance,
	VacationBalanceDetailsDTO,
	BalanceDetailsFilterForPeriod,
	VacationDto,
	VacationFilter,
	VCR,
} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "@/utils/StringUtils";
import VacationCreationModal from "@/components/payroll/vacation/VacationCreationModal.vue";
import VacationOptionsModal from "@/components/payroll/vacation/VacationOptionsModal.vue";
import VacationBalanceEditModal from "@/components/payroll/vacation/VacationBalanceEditModal.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import VacationService from "@/services/payroll/VacationService";
import PortalDate from "@/components/common/PortalDate.vue";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";
import {WorkspaceType} from "@/dto/auth/Workspace";
import VacationRequestCreationModal from "@/components/payroll/vacation/VacationRequestCreationModal.vue";
import VacationRequestEditor from "@/components/payroll/vacation/VacationRequestEditor.vue";
import VacationRequestView from "@/components/payroll/vacation/VacationReaquestView.vue";
import EmploymentVCRList from "@/components/payroll/vacation/vcr/EmploymentVCRList.vue";

@Component({
	computed: {
		WorkspaceType() {
			return WorkspaceType
		}
	},
	components: {EmploymentVCRList, PortalDate, PaginationComponent},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	}
})
export default class EmploymentVacation extends EmploymentVue<IEmployment> {
	private totalBalance: VacationBalance | null = null
	private vacations: Array<VacationDto> = [];
	private balanceDetails: Array<VacationBalanceDetailsDTO> = []
	private balance: number | null | string = null;
	private totalPages = 0
	private detailsPages = 0

	private vcrTrigger = 0

	@Prop()
	employment!: IEmployment

	private balanceDetailsFilter = new BalanceDetailsFilterForPeriod(
		{
			employmentId: this.employment.id
		}
	)

	private filter = new VacationFilter(
		{
			employmentsId: [this.employment.id!],
		}
	)

	private loadVacations() {
		Application.startLoading()
		return VacationService.getPageOfVacations(this.filter).then(
			res => {
				this.vacations = res.data.data
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}


	public created() {
		this.loadVacations()
			.then(() => this.loadBalanceDetails())
			.then(() => this.loadAvailableBalance())
	}

	private showCreationWindow() {
		this.$modal.show(
			VacationCreationModal,
			{
				employment: this.employment,
				callback: () => this.loadVacations()
			}
		)
	}

	private showOptions(vacation: VacationDto) {
		this.$modal.show(
			VacationOptionsModal,
			{
				vacation: vacation,
				callback: () => this.loadVacations()
			}
		)
	}


	private showCarryoverModal() {
		this.$modal.show(
			VacationBalanceEditModal,
			{
				employment: this.employment,
				callback: () => this.loadVacations()
			}
		)
	}

	private loadAvailableBalance() {
		Application.startLoading()
		return VacationService.getVacationBalance(this.employment.id!).then(
			res => {
				this.balance = res.data.totalBalanceDays;
				this.totalBalance = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private changePage(page: number){
		this.filter.page = page - 1;
		this.loadVacations();
	}

	private changeBalanceDetailsPage(page: number) {
		this.balanceDetailsFilter.page = page - 1;
		if (this.balanceDetailsFilter.startDate || this.balanceDetailsFilter.endDate) {
			this.loadBalanceDetailsForPeriod()
		} else {
			this.loadBalanceDetails()
		}
	}

	private loadBalanceDetails() {
		Application.startLoading();
		return VacationService.getBalanceDetails(this.balanceDetailsFilter).then(
			res => {
				this.balanceDetails = res.data.data
				this.detailsPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private reset() {
		this.filter = new VacationFilter(
			{
				employerId: this.$wss.getCurrent.id,
				startDate: moment().format("YYYY-MM-DD")
			}
		)
		this.loadVacations()
	}

	private showRequestModal() {
		this.$modal.show(
			VacationRequestCreationModal,
			{
				employment: this.employment,
				callback: () => {
					this.vcrTrigger = this.vcrTrigger + 1
				}
			}
		)
	}


	private loadBalanceDetailsForPeriod() {
		Application.startLoading()
		VacationService.getBalanceDetailsForPeriod(this.balanceDetailsFilter)
			.then(
				res => {
					this.balanceDetails = res.data.data
					this.totalPages = res.data.totalPages
					this.balance = res.data.balance
					Application.stopLoading()
				},
				err => processError(err, this)
			)
	}

	private resetBalanceDetailsForPeriod() {
		this.balanceDetailsFilter = new BalanceDetailsFilterForPeriod(
			{
				employmentId: this.employment.id,
			}
		)
		this.balance = null;
		this.loadAvailableBalance().then(
			() => this.loadBalanceDetails()
		)
	}

}
