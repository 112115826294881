





































import {Vue, Component, Prop} from 'vue-property-decorator';
import {VCR, VCRFilter, VCRStatus} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {processError} from "@/utils/ComponentUtils";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import VacationRequest from "@/components/payroll/vacation/vcr/VacationRequest.vue";
import moment, {Moment} from "moment-timezone";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {prettyEnum} from "../../../../utils/StringUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";

@Component({
	components: {PaginationComponent, VacationRequest, BTPortalCollapse},
	methods: {
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	}
})
export default class EmploymentVCRList extends Vue {
	@Prop()
	private employment!: IEmployment

	private vcrs: Array<VCR> = [];
	private reqTotalPages = 0
	private reqFilter = new VCRFilter(
		{
			employmentsId: [this.employment.id!],
		}
	)

	public created() {
		this.loadRequests()
	}

	private loadRequests() {
		Application.startLoading()
		return VacationService.getPageOfVacationRequests(this.reqFilter).then(
			res => {
				this.vcrs = res.data.data
				this.reqTotalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	resolveColor(vcr: VCR): string {
		switch (vcr.status) {
			case VCRStatus.APPROVED:
			case VCRStatus.COMPLETED:return "#3ECB7F"
			case VCRStatus.REQUESTED:
			case VCRStatus.IN_PROGRESS:return "#FEDC00"
			case VCRStatus.REJECTED:return "#E14C44"
			case VCRStatus.CANCELLED: return "#818181"
		}
	}

	private changeReqPage(page: number){
		this.reqFilter.page = page - 1;
		this.loadRequests();
	}
}
